import { useSelector } from "react-redux";
import { authUserSelector } from "reducers/authReducer";

export default function useInboxAddress() {
  const user = useSelector(authUserSelector);

  if (!user) {
    return null;
  }

  return `${user.username.toLowerCase()}@inbox.mailbrew.com`;
}
