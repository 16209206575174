import { Box, P1, useConfig } from "@mailbrew/uikit";

export default function StyledUserInboxAddress({ email, stretch, locked }) {
  const config = useConfig();
  return (
    <Box
      maxW="100%"
      overflow="hidden"
      px={3}
      height={config.Button.height}
      flex
      ai="center"
      jc="center"
      radius={2}
      background={config.colors.selection}
      style={{ flex: stretch ? 1 : 0, minHeight: config.Button.height }}
    >
      <P1
        align="center"
        color={config.colors.accent1}
        weight="500"
        noWrap
        style={{
          userSelect: locked ? "none" : "auto",
          pointerEvents: locked ? "none" : "auto",
        }}
      >
        {email}
      </P1>
    </Box>
  );
}
