import { Box, Icon, Stack, Tabs, useBreakpoint, useConfig, useDetectBrowser, useWindowSize } from "@mailbrew/uikit";
import { motion } from "framer-motion";
import React, { useEffect, useMemo, useRef, useState } from "react";
import StickySection from "./StickySection";

/**
 *
 * Items are users as tabs.
 *
 * The tabKey is the key that the Tabs component use when iterating on the items.
 * It's like the tab "value", and it's matched to the "active" prop to determine the active tab.
 *
 * The tabNameKey is the key to retrieve the tabName from the item, again for the Tabs component.
 *
 * onTabChange passed the current tab key to the handler when the user clicks on a tab.
 *
 * How many items are shown on screen is defined by the length of the items themselves,
 * approximated from their characters amount.
 *
 */

const TabsPicker = ({
  items,
  onTabChange: providedOnTabChange,
  active,
  tabKey,
  tabNameKey,
  style,
  getTabStyle,
  mt,
  mb,
}) => {
  const scrollerRef = useRef();
  const tabsRef = useRef();

  const config = useConfig();
  const { width: windowWidth } = useWindowSize({ debounceDelay: 100 });
  const { hasTouch } = useDetectBrowser();
  const [hit480, hit640] = useBreakpoint([480, 640]);

  const [cutOffPoint, setCutOffPoint] = useState(items?.length);
  const [expanded, setExpanded] = useState(false);
  const [fadeIn, setFadeIn] = useState(false);

  // Reset hooks if external items change, to prevent wrong computations
  useEffect(() => {
    setCutOffPoint(items?.length);
    setExpanded(false);
  }, [items?.length]);

  const noExpandUi = hasTouch && hit640;
  const showAllItems = noExpandUi || cutOffPoint >= items?.length;

  const shownItems = useMemo(() => {
    return showAllItems || expanded ? items : items.slice(0, cutOffPoint);
  }, [cutOffPoint, expanded, items, showAllItems]);

  useEffect(() => {
    // Calculate shown items width
    const shownItemsWidth = computeItemsWidth(shownItems, tabNameKey);
    // Already calculate also if there's one more item to shown
    const shownItemsPlusOneWidth = computeItemsWidth(items.slice(0, shownItems.length + 1), tabNameKey);

    if (!expanded && shownItemsWidth + 60 > windowWidth) {
      // If items don't fit the screen, reduce cut off point
      setCutOffPoint((prev) => prev - 1);
    } else {
      if (shownItemsPlusOneWidth + 60 < windowWidth) {
        // Otherwise increase it
        setCutOffPoint((prev) => prev + 1);
      }
    }
    // Fade in items after a little delay to hide the initial glitches
    const fadeInTimeout = setTimeout(() => {
      setFadeIn(true);
    }, 100);
    return () => clearTimeout(fadeInTimeout);
  }, [windowWidth, shownItems, tabNameKey, expanded, items]);

  /* ------------------------- Scroll to correct item ------------------------- */

  function handleTabClick(e) {
    if (e.currentTarget) {
      e.currentTarget.scrollIntoView({ behaviour: "smooth", block: "nearest", inline: "center" });
    }
  }

  function onTabChange(key) {
    const thisTab = items.find((item) => item?.[tabKey] === key);
    if (thisTab?.handler) {
      thisTab.handler();
    } else {
      providedOnTabChange?.(key);
    }
  }

  if (!items) return null;

  return (
    <StickySection style={style} mt={mt} mb={mb}>
      <Box
        as={motion.div}
        initial={{ opacity: 0, y: 10 }}
        animate={fadeIn ? { opacity: 1, y: 0 } : { opacity: 0, y: 10 }}
        transition={{ type: "spring", duration: 0.3, bounce: 0.05 }}
        ref={scrollerRef}
        mt={hit640 ? 1 : 0}
        overflow="scroll"
        w="100%"
        flex
        fd="row"
        ai="center"
        jc="flex-start"
        className="no-scrollbars"
      >
        <Stack mx="auto" noWrap align="center" style={{ zoom: hit480 ? 0.9 : 1 }} gap={0}>
          <div style={{ width: config.layout.padding }} />
          {shownItems && (
            <Tabs
              activeTab={active}
              tabs={shownItems.map((item) => item?.[tabKey])}
              tabsNames={shownItems.map((item) => item?.[tabNameKey])}
              tabsTooltips={shownItems.map((item) => item?.tooltip)}
              tabsIcons={shownItems.map((item) => item?.icon)}
              getTabStyle={getTabStyle}
              onTabChange={onTabChange}
              onTabClick={handleTabClick}
              style={{ zIndex: 2 }}
              underlineSpring={{ duration: 0 }}
              ref={tabsRef}
            />
          )}
          {!showAllItems && (
            <Icon
              ml={1}
              color={config.colors.c4}
              size="20px"
              variant="white"
              name={expanded ? "chevronLeft" : "chevronRight"}
              onClick={() => setExpanded(!expanded)}
            />
          )}
          {/* {hiddenBrews && <HiddenBrewsDropdown hiddenBrews={hiddenBrews} onBrewClick={onBrewClick} />} */}
          <div style={{ width: config.layout.padding }} />
        </Stack>
      </Box>
    </StickySection>
  );
};

function computeItemsWidth(items, key) {
  return items?.map((item) => item?.[key]).join("__").length * 8.3;
}

export default TabsPicker;
